.container {
  border-radius: 4px !important;
  background-color: unset !important;

  &:before {
    content: none !important;
  }

  :global {
    .MuiSelect-icon {
      width: 18px;
      height: 18px;
      top: 9px;
    }

    .MuiSelect-select {
      padding: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .MuiTypography-root {
        color: #2E3033;
        font-family: Fielmann-Sans;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }
}

.avatar {
  width: 24px !important;
  height: 24px !important;
  padding: 0;
  box-sizing: border-box;
  background-color: #494848 !important;
  margin-right: 8px;

  .avatarText {
    color: #FFF !important;
    font-family: Fielmann-Sans;
    font-size: 10px;
    font-weight: 500;
  }
}

.optionInactive {
  color: #9b9b9b !important;

  .avatar {
    background-color: #9b9b9b !important;
  }
}
